import React, { useEffect, useState } from "react";
import FormPageWrapper from "../../components/FormPageWrapper";
import { paddleScriptUrl } from "../../constants";
import { graphql, HeadProps, navigate, Script, useStaticQuery } from "gatsby";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import { CmPlan } from "../../types";
import Price from "../../features/checkout/Price";
import toast from "react-hot-toast";
import { useCurrentUserQuery } from "../../features/user/userApiSlice";
import Seo from "../../features/seo/Seo";
import { initializePaddleScript } from "../../features/checkout/checkoutUtils";
import * as Sentry from "@sentry/gatsby";
import { useActiveSubscriptionQuery } from "../../features/subscription/subscriptionApiSlice";

const Checkout = () => {
  const [paddleScriptInitialized, setPaddleScriptInitialized] = useState(false);
  const {
    allCmPlanCmPlan: { nodes: plans },
  }: {
    allCmPlanCmPlan: {
      nodes: CmPlan[];
    };
  } = useStaticQuery(graphql`
    query PlanQueryCheckout {
      allCmPlanCmPlan {
        nodes {
          billing_type
          title
          price_usd
          product_id
          created
          environment
        }
      }
    }
  `);
  const planMonthly = plans.find(
    (plan) =>
      plan.product_id === parseInt(process.env.GATSBY_PRODUCT_ID_MONTHLY) &&
      plan.environment === process.env.GATSBY_ENVIRONMENT
  );
  const planAnnual = plans.find(
    (plan) =>
      plan.product_id === parseInt(process.env.GATSBY_PRODUCT_ID_ANNUAL) &&
      plan.environment === process.env.GATSBY_ENVIRONMENT
  );

  const {
    data: currentUser,
    isFetching: isFetchingCurrentUser,
    isSuccess: isSuccessCurrentUserQuery,
  } = useCurrentUserQuery();

  const { data: activeSubscription, isFetching } = useActiveSubscriptionQuery();

  // Redirect anonymous users to /sign-up.
  useEffect(() => {
    if (
      isFetchingCurrentUser === false &&
      isSuccessCurrentUserQuery &&
      currentUser.is_authenticated === false
    ) {
      navigate("/sign-up");
    }
  }, [
    currentUser?.is_authenticated,
    isFetchingCurrentUser,
    isSuccessCurrentUserQuery,
  ]);

  // Redirect users who already have an active subscription.
  useEffect(() => {
    if (
      activeSubscription &&
      activeSubscription.bundle !== "free" &&
      !isFetching
    ) {
      const message =
        "You already have an active subscription. Please manage it in account settings";
      toast.error(message);
      console.error(message);
      Sentry.captureMessage(message, "warning");
      navigate("/user/settings");
    }
  }, [isFetching, activeSubscription]);

  useEffect(() => {
    if (typeof gtag === "function") {
      gtag("event", "begin_checkout");
    }
  }, []);

  const handleCheckoutOpen = (product: string) => {
    window.Paddle.Checkout.open({
      allowQuantity: false,
      email: currentUser.data.mail,
      passthrough: `{"uid": ${currentUser.data.uid}}`,
      marketingConsent: currentUser.data.field_marketing_consent,
      product: parseInt(product),
      success: `${process.env.GATSBY_SITEURL}/sign-up/thank-you`,
      disableLogout: true,
    });
  };

  return (
    <Layout isCheckout>
      <FormPageWrapper title="Sign up">
        <Script
          src={paddleScriptUrl}
          onLoad={() => {
            initializePaddleScript();
            setPaddleScriptInitialized(true);
          }}
        />
        <p className="mb-3 sm:mb-6 text-neutral-700">STEP 3 OF 3</p>
        <p className="mb-2 font-bold text-xl text-neutral-700">Choose a plan</p>
        {/* See https://developer.paddle.com/reference/ZG9jOjI1MzU0MDIw-checkout-parameters */}
        <div className="mx-auto space-y-4 max-w-md lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-2 lg:space-y-0">
          <div className="px-8 py-8 justify-center rounded-lg bg-neutral-100">
            <div className="mx-auto text-center text-2xl font-bold text-neutral-700">
              {planMonthly && (
                <Price
                  priceUsd={planMonthly.price_usd}
                  productId={planMonthly.product_id}
                />
              )}
              <span className="inline-block ml-1 font-normal text-base text-neutral-600">
                /month
              </span>
            </div>

            <div className="mt-6 flex justify-center">
              <button
                className="bg-blue-700 text-white rounded-2xl
    focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800 px-6 py-4 w-full text-xl"
                disabled={
                  !(paddleScriptInitialized && currentUser?.is_authenticated)
                }
                onClick={() => {
                  handleCheckoutOpen(process.env.GATSBY_PRODUCT_ID_MONTHLY);
                }}
                data-cy="signup-monthly"
              >
                START MONTHLY PLAN
              </button>
            </div>
          </div>
          <div className="px-8 py-8 justify-center rounded-lg bg-neutral-100 ring ring-blue-700 ring-offset-2">
            <div className="mx-auto text-center text-2xl font-bold text-neutral-700">
              {planAnnual && (
                <Price
                  priceUsd={planAnnual.price_usd}
                  productId={planAnnual.product_id}
                />
              )}
              <span className="inline-block ml-1 font-normal text-base text-neutral-600">
                /year
              </span>
              <StaticImage
                className="ml-4"
                src="../../features/frontpage/icon-best-value.svg"
                alt="Best value icon ribbon with dollar sign"
              />
            </div>

            <div className="mt-6 flex justify-center">
              <button
                className="bg-blue-700 text-white rounded-2xl
    focus:bg-gradient-to-r hover:bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700
    active:bg-blue-800 px-6 py-4 w-full text-xl"
                disabled={
                  !(paddleScriptInitialized && currentUser?.is_authenticated)
                }
                onClick={() => {
                  handleCheckoutOpen(process.env.GATSBY_PRODUCT_ID_ANNUAL);
                }}
              >
                SAVE ON A YEAR
              </button>
            </div>
          </div>
        </div>
      </FormPageWrapper>
    </Layout>
  );
};

export default Checkout;

export const Head = (props: HeadProps) => {
  return (
    <>
      <Seo
        title="Checkout | Giancoli Answers"
        pathname={props.location.pathname}
      />
      <meta name="robots" content="noindex" />
    </>
  );
};
